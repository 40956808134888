export const environment = {
  production: false,
  config: {
    serverUrl: 'https://caregap-inequities.qa.zsservices.com/',
    loginUrl:
      'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/login.aspx?ReturnUrl=',
    logoutUrl:
      'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/logout.aspx',
    idmAccountUrl:
      'https://idm.qa.zsservices.com/RWDI/IdentityManager/app/Web/MyAccount.aspx',
    env: 'qa',
    showCareGapDropdown: true,
  },
};
